.Page_Apartments_Details{
    padding-bottom: 70px;
}
.Page_Apartments_Details .number{
    width: 70px;
    text-align: center;
}
.Page_Apartments_Details .size{
    width: 200px;
    text-align: center;
}
.Page_Apartments_Details .cash{
    width: 140px;
    text-align: center;
}
.Page_Apartments_Details .list_mask{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.Page_Apartments_Details .list_mask .show_page_data{
    width: 44%;
    flex-grow: 1;
    position: relative;
}
.Page_Apartments_Details .list_mask .show_page_data .div_mask{
    position: absolute;
    width: calc(100% - 40px);
    display: flex;
}
.Page_Apartments_Details .list_mask .show_page_data .div_mask .effect{
    /* filter: brightness(0); */
    mix-blend-mode: soft-light;
}
.Page_Apartments_Details .list_mask .show_page_data .div_mask .bg_img_selected{
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    background-color: rgb(0 0 0 / 50%);
}
.Page_Apartments_Details .list_mask .show_page_data .img_selected{
    width: 100%;
    display: flex;
}
.Page_Apartments_Details .div_data_apart{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.Page_Apartments_Details .div_data_apart .color_apartments{
    width: 100px;
    height: 36px;
    border-radius: 6px;
    border: 1px solid #000000;
}
.Page_Apartments_Details .guidance{
    width: 120px;
}

.Page_Apartments_Details .border_top{
    border-bottom: 1px solid #e2e8f0;
    padding-bottom: 20px;
    margin-bottom: 20px;
}
.Page_Apartments_Details .border_top:nth-last-child(-n+1){
    border-bottom: 0px;
    margin-bottom: 0;
}