.PopUp .confirmation{
    width: 400px;
    margin: 0 auto;
}
.PopUp .confirmation .title{
    justify-content: center;
}
.PopUp .confirmation .div_confirmation{
    flex-wrap: wrap;
    flex-direction: row;
}
.PopUp .confirmation .div_data{
    flex-direction: column;
}
.PopUp .confirmation .alert{
    color: #f00000;
    margin-bottom: 20px;
}
.PopUp .confirmation .alert .attention{
    font-size: 18px;
    font-weight: 600;
}

@media only screen and (max-width: 440px) {
    .PopUp .confirmation {
        width: 90%;
    }
}