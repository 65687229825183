.Page_Config .border_top{
    border-bottom: 1px solid #e2e8f0;
    padding-bottom: 20px;
    margin-bottom: 20px;
}
.Page_Config .border_top:nth-last-child(-n+1){
    border-bottom: 0px;
    margin-bottom: 0;
}
.Page_Config .inf_date{
    width: 100%;
    text-align: right;
    font-size: 12px;
    font-style: italic;
}
.Page_Config .time_restart{
    width: 130px;
    text-align: center;
}