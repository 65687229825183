.Page_ScreenInitial .border_top{
    border-bottom: 1px solid #e2e8f0;
    padding-bottom: 20px;
    margin-bottom: 20px;
}
.Page_ScreenInitial .border_top:nth-last-child(-n+1){
    border-bottom: 0px;
    margin-bottom: 0;
}
.Page_ScreenInitial .data_select{
    width: 140px;
}