.Page_UsersDash_Details{
    padding-bottom: 70px;
}
.Page_UsersDash_Details .data_select{
    width: 140px;
}
.Page_UsersDash_Details .pass, .Page_UsersDash_Details .dash_user_name {
    width: 120px;
}
.Page_UsersDash_Details .dash_user_email {
    width: 220px;
}
.Page_UsersDash_Details .list_name_page{
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}
.Page_UsersDash_Details .list_data_mobile{
    display: none;
}
.Page_UsersDash_Details .list_data_mobile .data_mobile:nth-child(1n){
    padding-bottom: 20px;
    border-bottom: 1px solid;
}
.Page_UsersDash_Details .list_data_mobile .data_mobile:nth-last-child(-n + 1){
    border: none;
    padding: 0;
    margin-bottom: 0;
}
.Page_UsersDash_Details .list_data_mobile .nem_page{
    width: 80px;
}
.Page_UsersDash_Details .list_data_mobile .type_access{
    width: 100%;
}
.Page_UsersDash_Details .list_data_mobile .div_name_page{
    flex-grow: unset;
}
.Page_UsersDash_Details .div_name_page_access{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    border: 1px solid #2d3c53;
    padding: 4px 10px;
    border-radius: 6px;
}

@media only screen and (max-width: 500px) {
    .Page_UsersDash_Details .data_select{
        width: 100%;
    }
    .Page_UsersDash_Details .pass, .Page_UsersDash_Details .dash_user_name{
        width: calc(100% - 24px);
    }
    .Page_UsersDash_Details .dash_user_email {
        width: 220px;
    }    
    .Page_UsersDash_Details .list_name_page{
        display: none;
    }
    .Page_UsersDash_Details .list_data_mobile{
        display: block;
    }
}