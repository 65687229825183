.Page_Building{
    padding-bottom: 70px;
}
.Page_Building .type_file{
    width: calc(50% - 8px);
    text-align: center;
    background-color: #7c9f06;
    color: #ffffff;
    text-transform: uppercase;
    padding: 6px 0px;
    border-radius: 4px;
    cursor: pointer;
}
.Page_Building .type_file:hover, .Page_Building .type_file_active{
    background-color: #0b711c;
}

.Page_Building .list_input_data{
    justify-content: space-between;
}
.Page_Building .show_list_file .div_add_img{
    justify-content: flex-end;
    margin-bottom: 10px;
}
.Page_Building .list_input_data .div_list_file{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #2d3c53;
    border-radius: 6px;
    flex-grow: 1;
}
.Page_Building .list_input_data .div_list_file .numb_img{
    background-color: #2d3c53;
    height: 36px;
    width: 42px;
    min-width: 42px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px
}
.Page_Building .list_input_data .div_list_file .name_img{
    flex-grow: 1;
    padding: 0px 10px;
    min-width: 360px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.Page_Building .list_input_data .div_list_file .delete_img{
    padding-right: 5px;
}