.Page_UsersDash{
    width: 100%;
    height: auto;
    padding-bottom: 70px;
}
.Page_UsersDash .icons{
    cursor: pointer;
}
.Page_UsersDash .icon_user{
    width: 32px;
    height: 32px;
    border-radius: 50%;
    cursor: default;
}
.Page_UsersDash .icon_user_on {
    cursor: pointer;
}
.Page_UsersDash .icon_site{
    width: 24px;
    height: 24px;
}

@media only screen and (max-width: 580px) {
    .Page_UsersDash .td_hide{
        display: none;
    }
}