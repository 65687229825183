.PopUp .selected_point_canvas{
    width: 800px;
}
.PopUp .selected_point_canvas .div_data {
    flex-direction: column;
}
.PopUp .selected_point_canvas .type_div {
    flex-direction: row;
}
.PopUp .selected_point_canvas .content{
    position: relative;
    overflow: hidden;
}
.PopUp .selected_point_canvas .content .canvas{
    position: absolute;
    width: 100%;
    height: 100%;
}
.PopUp .selected_point_canvas .content .div_mask{
    position: absolute;
    top: 1px;
}   
.PopUp .selected_point_canvas .content .div_mask .img{
    mix-blend-mode: soft-light;
}
.PopUp .selected_point_canvas .content .img{
    width: 100%;
}

.PopUp .selected_point_canvas .color_selected{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;
}
.PopUp .selected_point_canvas .color_selected .div_show_color{    
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.PopUp .selected_point_canvas .color_selected .div_show_color .color{
    width: 80px;
    height: 26px;
}